import { twMerge } from "@mpay/web-tickets/src/tailwind/tailwindMerge";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import cx from "classnames";
import * as React from "react";

import { Text } from "./typography/Text";

const iconShimMap = {
  "exclamation-triangle": require("@heroicons/react/solid/ExclamationIcon"),
  check: require("@heroicons/react/solid/CheckIcon"),
  copy: require("@heroicons/react/solid/DocumentDuplicateIcon"),
  question: require("@heroicons/react/solid/QuestionMarkCircleIcon"),
  "user-group": require("@heroicons/react/solid/UserGroupIcon"),
  "book-open": require("@heroicons/react/outline/BookOpenIcon"),
  printer: require("@heroicons/react/solid/PrinterIcon"),
  trash: require("@heroicons/react/solid/TrashIcon"),
  "credit-card": require("@heroicons/react/solid/CreditCardIcon"),
  "chevron-up": require("@heroicons/react/solid/ChevronUpIcon"),
  "chevron-down": require("@heroicons/react/solid/ChevronDownIcon"),
  "arrow-right": require("@heroicons/react/solid/ArrowRightIcon"),
  exclamation: require("@heroicons/react/solid/ExclamationIcon"),
  warning: require("@heroicons/react/solid/ExclamationIcon"),
  edit: require("@heroicons/react/solid/PencilIcon"),
  plus: require("@heroicons/react/solid/PlusIcon"),
  "info-circle": require("@heroicons/react/solid/InformationCircleIcon"),
  "check-circle": require("@heroicons/react/solid/CheckCircleIcon"),
  times: require("@heroicons/react/solid/XIcon"),
  "thumbs-up": require("@heroicons/react/solid/ThumbUpIcon"),
  "thumbs-down": require("@heroicons/react/solid/ThumbDownIcon"),
  lock: require("@heroicons/react/solid/LockClosedIcon"),
  "location-marker": require("@heroicons/react/solid/LocationMarkerIcon"),
  "money-bill": require("@heroicons/react/solid/CashIcon"),
  key: require("@heroicons/react/solid/KeyIcon"),
  "eye-slash": require("@heroicons/react/solid/EyeOffIcon"),
  video: require("@heroicons/react/solid/VideoCameraIcon"),
  globe: require("@heroicons/react/solid/GlobeAltIcon"),

  spinner: function () {
    return (
      <>
        <div className="tw--ml-1" title="1">
          <svg
            version="1.1"
            id="loader-1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="24px"
            height="24px"
            viewBox="0 0 50 50"
            enableBackground="new 0 0 50 50"
            xmlSpace="preserve"
          >
            <path
              fill="#fff"
              d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      </>
    );
  },
  // "external link": null,
  // globe: null,
};
export type ValidIcon = keyof typeof iconShimMap;

const shimIconCva = cva(["tw-w-5 tw-inline-block tw-mb-0.5"], {
  variants: {
    size: {
      lg: "tw-w-8",
    },
    spin: {
      true: "tw-animate-spin",
    },
  },
  compoundVariants: [],
});

export function IconShim(
  props: {
    icon: ValidIcon;
    className?: string;
    right?: boolean;
    spin?: boolean;
    style?: React.CSSProperties;
  } & VariantProps<typeof shimIconCva>
) {
  const {
    icon,
    className,
    right = false,
    spin = false,
    style = {},
    size,
  } = props;
  const shimmedIcon = iconShimMap[icon];
  if (shimmedIcon) {
    return React.createElement(shimmedIcon, {
      className: twMerge(shimIconCva({ size, spin }), className),
      style: style,
    });
  } else {
    console.error(`Shim missing`, icon);
    return <div>X</div>;
  }
}

export default IconShim;
