"use client";

import {
  HasFullWidth,
  makeFullwidthModifierClasses,
} from "@mpay/web-tickets/src/components/common/ElementTypes";
import IconShim, {
  ValidIcon,
} from "@mpay/web-tickets/src/components/common/IconShim";
import { NextLink } from "@mpay/web-tickets/src/components/common/Link/NextLink";
import { twMerge } from "@mpay/web-tickets/src/tailwind/tailwindMerge";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import cx from "classnames";
import * as React from "react";

const ReactDom = require("react-dom");

// @ts-ignore
const useFormStatus = ReactDom.useFormStatus;

function iconify(
  icon: ValidIcon | React.ReactNode | null,
  isProcessing: boolean
): React.ReactNode | null {
  if (icon != null && isProcessing) {
    return <IconShim icon={"spinner"} spin style={{ marginTop: "-4px" }} />;
  }

  if (typeof icon === "string") {
    // @ts-expect-error
    return <IconShim icon={icon} />;
  } else {
    return icon;
  }
}

type ButtonBaseProps = {
  isBasic?: boolean;
  isRounded?: boolean;
  leftIcon?: ValidIcon | React.ReactNode;
  rightIcon?: ValidIcon | React.ReactNode;
  children?: React.ReactNode;
  isProcessing?: boolean;
  processOnClick?: boolean;
  defaultText?: string | React.ReactNode;
  processingText?: string | React.ReactNode;
  isDisabled?: boolean;
  isUnstyled?: boolean;
  processFromForm?: boolean;
} & HasFullWidth &
  VariantProps<typeof buttonCva>;

interface SubmitButtonProps extends ButtonBaseProps {
  href?: undefined;
  onClick?: undefined;
  type?: "submit";
}
interface ClickButtonProps extends ButtonBaseProps {
  href?: undefined;
  onClick: (() => void) | ((e: React.MouseEvent<HTMLElement>) => void);
  type?: "button" | "submit" | "reset";
}
interface AnchorButtonProps extends ButtonBaseProps {
  href: string;
  onClick?: undefined;
  type?: "button" | "submit" | "reset";
  external?: boolean;
}

type ClickButtonProps2 = ClickButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

type SubmitButtonProps2 = SubmitButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

type AnchorButtonsProps2 = AnchorButtonProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

export type ButtonProps =
  | ClickButtonProps2
  | AnchorButtonsProps2
  | SubmitButtonProps2;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      visualSize = "medium",
      isFullwidth = false,
      isMobileFullwidth = false,
      isBasic = false,
      isRounded = true,
      leftIcon = null,
      rightIcon = null,
      children = undefined,
      color = "white",
      isProcessing = false,
      defaultText = "",
      processingText = "",
      href = undefined,
      onClick = () => {},
      processOnClick = false,
      isDisabled = false,
      isUnstyled = false,
      className = "",
      shadow,
      processFromForm = false,
      ...props
    }: ButtonProps,
    ref
  ) {
    const [autoIsProcessing, setAutoIsProcessing] = React.useState(false);
    const { pending: reactFormPending } = useFormStatus();

    const computedIsProcessing =
      isProcessing || autoIsProcessing || (processFromForm && reactFormPending);

    const outOfUse = isDisabled || computedIsProcessing;
    const showLeftIcon = iconify(
      leftIcon,
      computedIsProcessing || autoIsProcessing
    );
    const showRightIcon = iconify(
      rightIcon,
      computedIsProcessing || autoIsProcessing
    );

    const contents = (
      <>
        {showLeftIcon && <span className="tw-mr-2">{showLeftIcon}</span>}
        {computedIsProcessing ? processingText : defaultText}
        {children}
        {showRightIcon && <span className="tw-ml-2">{showRightIcon}</span>}
      </>
    );

    const baseClass = cx([
      isRounded && "tw-rounded",
      "tw-border tw-border-gray-300",
      outOfUse && "tw-cursor-not-allowed",
      isDisabled && "tw-opacity-50",
      buttonCva({ shadow, color, isBasic, visualSize }),
    ]);

    const sharedClasses = twMerge(
      cx([
        isUnstyled ? null : baseClass,
        "tw-flex tw-items-center tw-justify-center",
        "tw-cursor-pointer disabled:tw-cursor-not-allowed",
        makeFullwidthModifierClasses(isFullwidth, isMobileFullwidth),
        outOfUse && "tw-cursor-not-allowed",
      ])
    );

    if (href) {
      return (
        <NextLink
          {...(props as AnchorButtonsProps2)}
          className={twMerge(sharedClasses, "tw-inline-flex", className)}
          href={href}
          onClick={outOfUse ? undefined : onClick}
        >
          {contents}
        </NextLink>
      );
    } else {
      return (
        <button
          ref={ref}
          type="button"
          {...(props as ClickButtonProps2)}
          className={twMerge(sharedClasses, className)}
          onClick={
            outOfUse
              ? undefined
              : (...args) => {
                  if (processOnClick) {
                    return Promise.resolve().then(async () => {
                      try {
                        setAutoIsProcessing(true);
                        return await onClick(...args);
                      } finally {
                        setAutoIsProcessing(false);
                      }
                    });
                  } else {
                    return onClick(...args);
                  }
                }
          }
          disabled={outOfUse}
        >
          {contents}
        </button>
      );
    }
  }
);

const buttonCva = cva([], {
  variants: {
    isBasic: {
      true: "",
      false: "",
    },
    shadow: {
      none: "tw-shadow-none",
      medium: "tw-shadow",
      large: "tw-shadow-lg",
    },
    color: {
      blue: "",
      indigo: "",
      green: "",
      red: "",
      orange: "",
      white: "",
      dark: "",
    },
    visualSize: {
      small: "tw-px-2 tw-py-1 tw-text-sm tw-font-normal tw-h-8",
      medium: "tw-px-3 tw-py-1.5 tw-text-base tw-font-medium tw-h-10",
      large: "tw-px-4 tw-py-2 tw-text-lg tw-font-medium",
    },
  },
  compoundVariants: [
    {
      isBasic: true,
      color: "blue",
      className: [
        "tw-text-blue-600 tw-border-blue-500",
        "hover:tw-bg-blue-50 hover:tw-text-blue-700",
      ],
    },
    {
      isBasic: true,
      color: "indigo",
      className: [
        "tw-text-indigo-600 tw-border-indigo-500",
        "hover:tw-bg-indigo-50 hover:tw-text-indigo-700",
      ],
    },
    {
      isBasic: true,
      color: "green",
      className: [
        "tw-text-green-600 tw-border-green-500",
        "hover:tw-bg-green-50 hover:tw-text-green-700",
      ],
    },
    {
      isBasic: true,
      color: "red",
      className: [
        "tw-text-red-600 tw-border-red-500",
        "hover:tw-bg-red-50 hover:tw-text-red-700",
      ],
    },
    {
      isBasic: true,
      color: "orange",
      className: [
        "tw-text-orange-600 tw-border-orange-500",
        "hover:tw-bg-orange-50 hover:tw-text-orange-700",
      ],
    },
    {
      isBasic: true,
      color: "white",
      className: ["tw-text-gray-800 tw-bg-white", "hover:tw-bg-gray-100"],
    },
    {
      isBasic: false,
      color: "blue",
      className: [
        "tw-text-white tw-bg-blue-500 tw-border-none",
        "hover:tw-bg-blue-600",
      ],
    },
    {
      isBasic: false,
      color: "indigo",
      className: [
        "tw-text-white tw-bg-indigo-500 tw-border-none",
        "hover:tw-bg-indigo-600",
      ],
    },
    {
      isBasic: false,
      color: "green",
      className: [
        "tw-text-white tw-bg-green-500 tw-border-none",
        "hover:tw-bg-green-600",
      ],
    },
    {
      isBasic: false,
      color: "red",
      className: [
        "tw-text-white tw-bg-red-500 tw-border-none",
        "hover:tw-bg-red-600",
      ],
    },
    {
      isBasic: false,
      color: "orange",
      className: [
        "tw-text-white tw-bg-orange-500 tw-border-none",
        "hover:tw-bg-orange-600",
      ],
    },
    {
      isBasic: false,
      color: "white",
      className: [
        "tw-text-gray-800 tw-bg-white tw-border-none",
        "hover:tw-bg-gray-100",
      ],
    },
    {
      isBasic: false,
      color: "dark",
      className: [
        "tw-text-white tw-bg-gray-600 tw-border-none",
        "hover:tw-bg-gray-700",
      ],
    },
  ],
});
