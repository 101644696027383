export type ElementVisualSize = "small" | "medium" | "large";

export type HasVisualSize = { visualSize?: ElementVisualSize };
export type HasFullWidth = {
  isFullwidth?: boolean;
  isMobileFullwidth?: boolean;
};

export function makeFullwidthModifierClasses(
  isFullwidth: boolean,
  isMobileFullwidth: boolean
) {
  return [
    isMobileFullwidth && `tw-w-full md:tw-w-auto`,
    isFullwidth && `tw-w-full md:tw-w-full`,
  ];
}
